<template>
  <b-card-code title="جدول نبذات الاقسام" no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- filter -->
        <b-form-group
          label="فلتر"
          label-cols-sm="1"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="ابحث عن.."
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                الغاء الفلتر
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                v-b-modal.model-a-w
              >
                <feather-icon icon="FilePlusIcon" class="mr-50" />
                <span class="align-middle">اضافة نبذة جديدة</span>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>
<div class="demo-vertical-spacing">
      <b-progress
      v-if="precentage !== 0"
        :value="precentage"
        max="100"
      />
      </div>
    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(action)="action">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-warning"
              v-b-modal.model-e-w
              @click="eRM(action.item)"
            >
              <feather-icon icon="EditIcon" class="mr-50" />
            </b-button>
          </b-col>
          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              v-b-modal.modal-center
              @click="dRM(action.item)"
            >
              <feather-icon icon="Trash2Icon" class="mr-50" />
            </b-button>
          </b-col>
        </b-dropdown>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          @change="check()"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
       <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>
    <b-modal
      id="model-e-w"
      ok-title="حفظ التعديلات"
      centered
      size="lg"
      title="تعديل النبذة"
      @show="checkModel"
      @hidden="rEF"
      @ok="uM"
    >
      <b-form novalidate class="needs-validation">
        <b-form-row>
          <b-col md="12">
            <label for="input-valid1">رؤية القسم بالعربي:</label>
            <quill-editor ref="editformar_vision" v-model="editform.ar_vision" :options="snowOption" />
          </b-col>
          <b-col md="12">
            <label for="input-invalid1">رؤية القسم بالانكليزي:</label>
            <quill-editor ref="editformen_vision" v-model="editform.en_vision" :options="snowOption" />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">رسالة القسم بالعربي:</label>
            <quill-editor ref="editformar_message" v-model="editform.ar_message" :options="snowOption" />
          </b-col>
          <b-col md="12">
            <label for="input-invalid1">رسالة القسم بالانكليزي:</label>
            <quill-editor ref="editformen_message" v-model="editform.en_message" :options="snowOption" />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">الاهداف بالعربية:</label>
            <quill-editor ref="editformar_goals" v-model="editform.ar_goals" :options="snowOption" />
          </b-col>
          <b-col md="12">
            <label for="input-invalid1">الاهداف بالانكليزية:</label>
            <quill-editor ref="editformen_goals" v-model="editform.en_goals" :options="snowOption" />
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="basic-password">القسم</label>
              <v-select
                v-model="editform.department_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="departments"
                placeholder="اختر القسم"
              />
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>
    <b-modal
      id="model-a-w"
      ok-title="حفظ البيانات"
      centered
      size="lg"
      title="اضافة نبذة جديدة"
      @show="checkModel"
      @hidden="rF"
      @ok="aNM"
    >
      <b-form novalidate class="needs-validation">
        <b-form-row>
        <b-col md="12">
            <label for="input-valid1">رؤية القسم بالعربي:</label>
            <quill-editor ref="formar_vision" v-model="form.ar_vision" :options="snowOption" />
          </b-col>
          <b-col md="12">
            <label for="input-invalid1">رؤية القسم بالانكليزي:</label>
            <quill-editor ref="formen_vision" v-model="form.en_vision" :options="snowOption" />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">رسالة القسم بالعربي:</label>
            <quill-editor ref="formar_message" v-model="form.ar_message" :options="snowOption" />
          </b-col>
          <b-col md="12">
            <label for="input-invalid1">رسالة القسم بالانكليزي:</label>
            <quill-editor ref="formen_message" v-model="form.en_message" :options="snowOption" />
          </b-col>
          <b-col md="12">
            <label for="input-valid1">الاهداف بالعربية:</label>
            <quill-editor ref="formar_goals" v-model="form.ar_goals" :options="snowOption" />
          </b-col>
          <b-col md="12">
            <label for="input-invalid1">الاهداف بالانكليزية :</label>
            <quill-editor ref="formen_goals" v-model="form.en_goals" :options="snowOption" />
          </b-col>
          <b-col md="12">
            <b-form-group>
              <label for="basic-password">القسم</label>
              <v-select
                v-model="form.department_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="departments"
                placeholder="اختر القسم"
              />
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>
    </b-modal>

    <b-modal
      id="modal-center"
      centered
      title="حذف النبذة "
      @ok="dM"
      ok-title="حذف الحقل"
    >
      <b-card-text>
        هل تريد حذف هذه الحقل " {{ this.editform.ar_name }} " ?
      </b-card-text>
    </b-modal>
  </b-card-code>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import vSelect from "vue-select";
import store from "@/store";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BAvatar,
  BModal,
  BDropdown,
  BDropdownItem,
  BFormCheckboxGroup,
  BForm,
  BFormRow,
  BCol,
  VBModal,
  BBadge,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BFormGroup,
  BFormCheckbox,
  BCardText,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BProgress,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCardCode,
    BTable,
    BForm,
    vSelect,quillEditor,
    BAvatar,
    BBadge,
    BFormRow,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    togglePasswordVisibility,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormCheckboxGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BModal,BProgress,
    VBModal,
    Ripple,
    BCol,
    BFormCheckbox,
    BCardText,
    BFormValidFeedback,
    BFormInvalidFeedback,
  },
  data() {
    return {
      precentage:0,
      teacher_id: "",
      perPage: 3,
      pageOptions: [3, 5, 10, 100],
      totalRows: 1,
      currentPage: 0,
      department: "",
      title: "",
      filter: null,
      filterOn: [],
      departments: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      teachers: [],
      stages: [],
      fields: [
        {
          key: "ar_vision",
          label: "الرؤية بالعربي",
        },
        { key: "department.ar_name", label: "القسم" },
        { key: "action", label: "الاجراءات" },
      ],
      items: [],
      formModal:{},
      form: {
        ar_vision: ``,
        en_vision: ``,
        ar_message: ``,
        en_message: ``,
        en_goals: ``,
        ar_goals: ``,
        department_id: "",
      },
      editform: {
        ar_vision: ``,
        en_vision: ``,
        ar_message: ``,
        en_message: ``,
        en_goals: ``,
        ar_goals: ``,
        department_id: "",
        id: null,
      },
      selected: [],
      selected2: [],
      snowOption: {
        theme: 'snow',
      },
      content: ``,
    };
  },
  watch: {
    "editform.department_id": function (val) {
      if (val != null || val != "") {
        this.gSM1();
      }
    },
    "form.department_id": function (val) {
      if (val != null || val != "") {
        this.gSM3();
      }
    },
    "editform.ar_vision": function () {
      this.formModal.ar_vision = JSON.stringify(
        this.$refs.editformar_vision.quill.getContents())
    },
    "editform.en_vision": function (val) {
      this.formModal.en_vision = JSON.stringify(
        this.$refs.editformen_vision.quill.getContents()
      );
    },
    "editform.ar_message": function (val) {
      this.formModal.ar_message = JSON.stringify(
        this.$refs.editformar_message.quill.getContents()
      );
    },
    "editform.en_message": function (val) {
       this.formModal.en_message = JSON.stringify(
        this.$refs.editformen_message.quill.getContents()
      );
    },
    "editform.ar_goals": function (val) {
      this.formModal.ar_goals = JSON.stringify(
        this.$refs.editformar_goals.quill.getContents()
      );
    },
    "editform.en_goals": function (val) {
      this.formModal.en_goals = JSON.stringify(
        this.$refs.editformen_goals.quill.getContents()
      );
    },
    "form.ar_vision": function (val) {
      this.formModal.ar_vision = JSON.stringify(
        this.$refs.formar_vision.quill.getContents()
      );
    },
    "form.en_vision": function (val) {
      this.formModal.en_vision = JSON.stringify(
        this.$refs.formen_vision.quill.getContents()
      );
    },
    "form.ar_message": function (val) {
      this.formModal.ar_message = JSON.stringify(
        this.$refs.formar_message.quill.getContents()
      );
    },
    "form.en_message": function (val) {
      this.formModal.en_message = JSON.stringify(
        this.$refs.formen_message.quill.getContents()
      );
    },
    "form.en_goals": function (val) {
      this.formModal.en_goals = JSON.stringify(
        this.$refs.formen_goals.quill.getContents()
      );
    },
    "form.ar_goals": function (val) {
      this.formModal.ar_goals = JSON.stringify(
        this.$refs.formar_goals.quill.getContents()
      );
    },
  },
  mixins: [togglePasswordVisibility],
  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  directives: {
    Ripple,
  },
  mounted() {
    this.ID(), this.gR(), this.gSM(), this.gSM2();
  },
  methods: {
    async gSM() {
      await this.axios
        .get(
          `since/deparment?take=100&skip=${
            this.currentPage - 1
          }&select=true`
        )
        .then((res) => {
          this.departments = [];
          this.departments = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gSM1() {
      this.stages = [];
      this.editform.stage_id == "";
      console.log(this.form.department_id);
      if (!Number.isInteger(this.editform.department_id)) {
        this.editform.department_id = this.editform.department_id["id"];
      }
      await this.axios
        .get(
          `stage/clint/department/${this.editform.department_id}?select=true`
        )
        .then((res) => {
          this.stages = [];
          this.stages = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gSM3() {
      this.stages = [];
      this.form.stage_id == "";
      if (!Number.isInteger(this.form.department_id)) {
        this.form.department_id = this.form.department_id["id"];
      }
      await this.axios
        .get(`stage/clint/department/${this.form.department_id}?select=true`)
        .then((res) => {
          this.stages = [];
          this.stages = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gSM2() {
      this.teachers = [];
      this.editform.teacher_id == "";
      await this.axios
        .get(`teachers-name`)
        .then((res) => {
          this.teachers = [];
          this.teachers = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    checkModel() {
      this.selected = [];
      if (this.editform.is_sustainabilty == 1) {
        this.selected.push("is_sustainabilty");
      }
      if (this.editform.is_energy == 1) {
        this.selected.push("is_energy");
      }
    },
    resetCheckboxes() {
      this.selected = [];
    },
    async uM() {
      if (!Number.isInteger(this.editform.department_id)) {
        this.formModal.department_id = this.editform.department_id["id"];
      }else{
        this.formModal.department_id =this.editform.department_id
      }
      await this.axios
        .post(`about/${this.editform.id}`, this.formModal,{
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gR();
            this.makeToast(
              "success",
              "update Success",
              "Your update is done successfully"
            );
          }
          this.precentage=0
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async aNM() {
       if (!Number.isInteger(this.form.department_id)) {
        this.formModal.department_id = this.form.department_id["id"];
      }else{
        this.formModal.department_id =this.form.department_id
      }
      await this.axios
        .post(`about`, this.formModal,{
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        })
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gR();
            this.makeToast(
              "success",
              "update Success",
              "Your model is added successfully"
            );
          }
          this.rF();
          this.precentage=0
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
      this.rF();
    },
    rF() {
      this.form.ar_vision= ``,
      this.form.en_vision= ``,
      this.form.ar_message= ``,
      this.form.en_message= ``,
      this.form.en_goals= ``,
      this.form.ar_goals= ``,
      this.form.department_id= ""
    },
    rEF() {
      
      this.editform.ar_vision= ``,
      this.editform.en_vision= ``,
      this.editform.ar_message= ``,
      this.editform.en_message= ``,
      this.editform.en_goals= ``,
      this.editform.ar_goals= ``,
      this.editform.department_id= "",
      this.editform.id= null
    },
    async dM() {
      await this.axios
        .delete(`about/${this.editform.id}`)
        .then((res) => {
          if (res.data.errors) {
            this.errorToast();
          } else {
            this.gR();
            this.makeToast(
              "success",
              "Delete Success",
              "Your item is deleted successfully"
            );
          }
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "warning",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    errorToast() {
      this.makeToast(
        "danger",
        "update failed",
        "some thing want wrong, i`m sorry :("
      );
    },
    eRM(item) {
      this.editform.ar_vision= `${item.ar_vision}`,
      this.editform.en_vision= `${item.en_vision}`,
      this.editform.ar_message= `${item.ar_message}`,
      this.editform.en_message= `${item.en_message}`,
      this.editform.en_goals= `${item.en_goals}`,
      this.editform.ar_goals= `${item.ar_goals}`,
      this.editform.department_id= item.department_id,
      this.editform.id= item.id
      setTimeout(() => {
        if (this.editform.ar_vision != null) {
          this.$refs.editformar_vision.quill.setContents(
            JSON.parse(this.editform.ar_vision).ops
          );
        }
        if (this.editform.en_vision != null) {
          this.$refs.editformen_vision.quill.setContents(
            JSON.parse(this.editform.en_vision).ops
          );
        }
        if (this.editform.ar_message != null) {
          this.$refs.editformar_message.quill.setContents(
            JSON.parse(this.editform.ar_message).ops
          );
        }
        if (this.editform.en_message != null) {
          this.$refs.editformen_message.quill.setContents(
            JSON.parse(this.editform.en_message).ops
          );
        }
        if (this.editform.ar_goals != null) {
          this.$refs.editformar_goals.quill.setContents(
            JSON.parse(this.editform.ar_goals).ops
          );
        }
        if (this.editform.en_goals != null) {
          this.$refs.editformen_goals.quill.setContents(
            JSON.parse(this.editform.en_goals).ops
          );
        }
      }, 0);
    },
    dRM(item) {
      this.editform.ar_vision = item.ar_vision;
      this.editform.id = item.id;
    },
    check() {
      setTimeout(() => this.gR(), 1000);
    },
    ID() {
      this.teacher_id = localStorage.getItem("NisourTeacherId");
    },
    async gS() {
      if (!Number.isInteger(this.department) & (this.department != "")) {
        this.department = this.department["id"];
      }
      await this.axios
        .get(
          `lecture?take=${this.perPage}&skip=${
            this.currentPage - 1
          }&department=${this.department}&title=${this.title}`
        )
        .then((res) => {
          this.items = [];
          this.totalRows = res.data.totalCount;
          this.items = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    async gR() {
      await this.axios
        .get(`about?take=${this.perPage}&skip=${this.currentPage - 1}`)
        .then((res) => {
          this.items = [];
          this.totalRows = res.data.totalCount;
          this.items = res.data.items;
        })
        .catch((e) => {
          if (e.message.includes("401")) {
            this.makeToast(
              "danger",
              "update failed",
              "you don't a permision to do this action ;)"
            );
          } else {
            this.errorToast();
          }
        });
    },
    makeToast(variant = null, title = null, body = null) {
      this.$bvToast.toast(body, {
        title: title,
        variant,
        solid: false,
      });
    },
    resetSearch() {
      (this.title = ""), (this.department = "");
    },
    
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>